<template>
  <div>
    <div class="panel" v-loading="loading">
      <div class="panel-item">
        <header>
          Үнэлгээ
        </header>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12">Нийт</el-col>
          <el-col :span="12" class="text-right">{{nbTotal}}</el-col>
        </el-row>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12">Дундаж үнэлгээ</el-col>
          <el-col :span="12" class="text-right"><i class="el-icon-star-on"></i> {{avgStar}}</el-col>
        </el-row>
      </div>
      <div class="panel-item">
        <div class="cursor-pointer text-primary" @click="onPressDetail">Дэлгэрэнгүй</div>
      </div>
    </div>
    <el-dialog title="Үнэлгээ" :visible.sync="dialogTableVisible" top="10px">
      <div>
        <!-- <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="12">
            <span class="text-xl">
              <i class="el-icon-star-on"></i> {{currentOutlet.avg_star}}
            </span>
            ({{currentOutlet.reviews.length}} үнэлгээ)
          </el-col>
        </el-row> -->
        <el-col :span="12">
            <div class="text-left">
              <el-select v-model="choosedProduct" placeholder="Бүтээгдэхүүн сонгох" style="width:300px" @change="setCurrentProduct">
                <el-option
                  v-for="(item, index) in productsData"
                  :key="index"
                  :label="item.name_mon"
                  :value="item.productId">
                </el-option>
              </el-select>
            </div>
          </el-col>
        <el-table :data="reviewData">
          <el-table-column property="desc" label="Сэтгэгдэл"></el-table-column>
          <el-table-column property="star" label="Үнэлгээ" width="100">
            <template slot-scope="scope">
              <i class="el-icon-star-on" v-for="(counter, index) in scope.row.star" :key="index"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import { getSupplierId } from '@/utils/auth.js'
export default {
  name: 'ReviewStat',
  data () {
    return {
      dialogTableVisible: false,
      loading: false,
      nbTotal: 0,
      avgStar: 0,
      reviewOutlets: [],
      currentOutlet: null,
      currentReviews: [],
      choosedProduct: null,
      productsData: [],
      reviewData: []
    }
  },
  async created () {
    this.getReviews(await getSupplierId())
  },
  methods: {
    setCurrentProduct () {
      this.getReviewsByProductId()
    },
    getReviewsByProductId () {
      this.isLoading = true
      const body = {
        product_id: this.choosedProduct
      }
      services.getReviewsByProductId(body).then(response => {
        this.isLoading = false
        if (response.status === 'success' && response.data) {
          this.reviewData = response.data
        }
      })
    },
    async onPressDetail () {
      this.dialogTableVisible = true
      this.getProductsBySupplierId()
    },
    getProductsBySupplierId () {
      this.isLoading = true
      const body = {
        size: 1000,
        from: 0
      }
      services.getProductsBySupplierId(body).then(response => {
        this.isLoading = false
        if (response.status === 'success' && response.data) {
          this.productsData = response.data
        }
      })
    },
    getReviews (supplierIds) {
      this.loading = true
      const body = {
        supplier_id: supplierIds
      }
      services.getReviews(body).then(response => {
        if (response.status === 'success') {
          this.loading = false
          this.reviewOutlets = response.data
          this.nbTotal += this.reviewOutlets.total
          this.avgStar += this.reviewOutlets.avg
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.message,
            type: 'error',
            position: 'top-right'
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.panel{
  padding-bottom: 0;
}
.panel-item{
  padding: 15px 20px
}
.panel-item header{
  margin-bottom: 0;
}
</style>
