<template>
  <div>
    <div class="panel" v-loading="loading">
      <div class="panel-item">
        <header>
          Бүтээгдэхүүн
        </header>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12">Нийт</el-col>
          <el-col :span="12" class="text-right">{{nbTotal}}</el-col>
        </el-row>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12">Идэвхитэй</el-col>
          <el-col :span="12" class="text-right">{{nbActive}}</el-col>
        </el-row>
      </div>
      <div class="panel-item cursor-pointer text-primary" @click="dialogTableVisible = true">
        <el-row>
          <el-col :span="12">Идэвхигүй</el-col>
          <el-col :span="12" class="text-right">{{nbTotal - nbActive}}</el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="Идэвхигүй бүтээгдэхүүн" :visible.sync="dialogTableVisible" top="10px">
      <div v-if="inactiveData">
        <el-row :gutter="20" type="flex" align="middle">
          <el-col :span="12">
            Нийт: {{inactiveData.length}}
          </el-col>
        </el-row>
        <el-table :data="inactiveData" v-if="inactiveData.length > 0">
          <el-table-column width="100">
            <template slot-scope="prop">
              <div class="image-holder">
                <img :src="prop.row.image" alt="" style="width: 100px">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name_mon" label="Нэр"></el-table-column>
          <el-table-column label="Үнэ" width="100">
            <template slot-scope="prop">
              {{prop.row.get_price}}₮
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import { getSupplierId } from '@/utils/auth.js'
export default {
  name: 'ProductStat',
  data () {
    return {
      dialogTableVisible: false,
      loading: false,
      nbTotal: 0,
      nbActive: 0,
      productOutlets: [],
      inactiveData: null,
      currentReviews: [],
      choosedOutlet: ''
    }
  },
  async created () {
    this.getProductsStat(await getSupplierId())
  },
  methods: {
    setCurrentOutlet () {
      this.currentOutlet = this.productOutlets.filter(item => item.outlet_name === this.choosedOutlet)[0]
    },
    getProductsStat (supplierIds) {
      this.loading = true
      const body = {
        supplier_id: supplierIds
      }
      services.getProductsStat(body).then(response => {
        this.loading = false
        if (response.status === 'success') {
          this.productOutlets = response.data
          this.nbTotal += this.productOutlets.total
          this.nbActive += this.productOutlets.active
          this.inactiveData = this.productOutlets.inactiveData
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.message,
            type: 'error',
            position: 'top-right'
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.panel{
  padding-bottom: 0;
}
.panel-item{
  padding: 15px 20px
}
.panel-item:last-child{
}
.panel-item header{
  margin-bottom: 0;
}
</style>
