<template>
  <div class="panel tabled" v-loading="loading">
    <div class="panel-content">
      <el-tabs v-model="activeTab" @tab-click="tabClicked">
        <el-tab-pane label="Борлуулалтаар" name="sales" class="pl20 pr20">
          <apexchart :options="salesChartOptions" :series="salesChartSeries" height="330" v-if="salesChartSeries"></apexchart>
        </el-tab-pane>
        <el-tab-pane label="Захиалгаар" name="tran" class="pl20 pr20">
          <apexchart :options="salesChartOptions" :series="salesChartSeries" height="330" v-if="salesChartSeries"></apexchart>
        </el-tab-pane>
        <el-tab-pane label="Захиалгын дундаж үнэ" name="ta" class="pl20 pr20">
          <apexchart :options="salesChartOptions" :series="salesChartSeries" height="330" v-if="salesChartSeries"></apexchart>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import services from '@/helpers/services.js'
import helper from '@/helpers/helper.js'
export default {
  name: 'SalesChart',
  props: [
    'outletIds',
    'outletNames',
    'dateRanges'
  ],
  data () {
    return {
      activeTab: 'sales',
      loading: false,
      salesChartOptions: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'datetime',
          min: new Date(this.dateRanges[0]).getTime()
        }
      },
      salesChartSeries: []
    }
  },
  computed: {
    data: () => {
      return this.salesChartSeries
    }
  },
  created () {
    this.getSalesReport(this.$supplierIds, this.dateRanges)
  },
  watch: {
    dateRanges: function (newval, old) {
      this.getSalesReport(this.outletIds, newval)
    },
    outletIds: function (newval, old) {
      this.getSalesReport(newval, this.dateRanges)
    }
  },
  methods: {
    parseDateRange (ranges) {
      const start = this.$helpers.formatDate(ranges[0], 'YYYY-MM-DD')
      const end = this.$helpers.formatDate(ranges[1], 'YYYY-MM-DD')
      const diffDays = dayjs(end).diff(dayjs(start), 'day')
      const dateArray = []
      let startTimestamp = new Date(start).getTime()
      for (let i = 0; i <= diffDays; i++) {
        dateArray.push(this.$helpers.formatDate(startTimestamp, 'MM-DD'))
        startTimestamp = startTimestamp + 3600 * 24 * 1000
      }
      return dateArray
    },
    tabClicked () {
      this.getSalesReport(this.$supplierIds, this.dateRanges)
    },
    getSalesReport (supplierIds, dateRanges = []) {
      this.loading = true
      const body = {
        supplier_id: supplierIds,
        start_date: helper.formatDate(dateRanges[0], 'YYYY-MM-DD 00:00:00'),
        end_date: helper.formatDate(new Date(dateRanges[1]).getTime() - 3600 * 24 * 1000, 'YYYY-MM-DD 23:59:59'),
        type: this.activeTab
      }
      services.getSalesReport(body).then(response => {
        if (response.status === 'success') {
          this.loading = false
          this.salesChartSeries = []
          this.salesChartSeries[0] = {
            name: 'Одоогийх',
            data: response.data.requested_data.map(item => {
              return [new Date(item.day).getTime(), item.value]
            })
          }
          this.salesChartSeries[1] = {
            name: 'Өмнөх',
            data: response.data.compared_data.map((item, index) => {
              return [new Date(response.data.requested_data[index].day).getTime(), item.value]
            })
          }
          this.salesChartOptions = {
            ...this.salesChartOptions,
            ...{
              xaxis: {
                min: new Date(response.data.requested_data[0].day).getTime()
              }
            }
          }
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>
