<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="6">
          <h2>Тайлан</h2>
        </el-col>
        <el-col :span="18" class="text-right">
          <el-date-picker
            v-model="dateRange"
            default-value="2021-06-01"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            start-placeholder="Эхлэх огноо"
            end-placeholder="Дуусах огноо"
            :picker-options="pickerOptions"
            @change="getAllReports">
          </el-date-picker>
        </el-col>
      </el-row>
    </header>
    <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="16">
        <sales-chart :outletIds.sync="choosedOutletIds" :dateRanges.sync="dateRange" :outletNames.sync="choosedOutletNames"></sales-chart>
      </el-col>
      <el-col :span="8">
        <product-stat></product-stat>
        <review-stat></review-stat>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SalesChart from './components/SalesChart'
import ReviewStat from './components/ReviewStat.vue'
import ProductStat from './components/ProductStat.vue'
export default {
  components: {
    SalesChart,
    ReviewStat,
    ProductStat
  },
  data () {
    return {
      choosedOutletIds: [],
      choosedOutletNames: [],
      defaultEndDate: new Date(),
      defaultStartDate: new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7),
      dateRange: null,
      salesReportType: 'sales',
      salesChartLoading: false,
      salesReportData: null,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '7 хоногоор',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '12 сараар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      outlets: []
    }
  },
  created () {
    this.dateRange = [this.defaultStartDate, this.defaultEndDate]
    this.getAllReports()
  },
  methods: {
    getAllReports () {
      if (this.dateRange.length === 0) {
        this.$notify({
          title: 'Алдаа',
          message: 'Өдрийн интервалийг сонгоно уу',
          type: 'error',
          position: 'top-right'
        })
        return false
      }
    }
  }
}
</script>
<style scoped>
.panel{
  padding-bottom: 0;
}
.panel-item{
  padding: 15px 20px
}
.panel-item header{
  margin-bottom: 0;
}
</style>
